import {json, MetaFunction} from "@remix-run/node";
import Hero from '~/routes/_index/Hero';
import {VStack} from '@styled-system/jsx';
import ActivePromotions from '~/routes/_index/ActivePromotions';
import JoinVipFooter from '~/components/layout/JoinVipFooter';
import BuildingDreamsTogether from '~/routes/_index/BuildingDreamsTogether';
import {useLoaderData} from '@remix-run/react';
import {differenceInDays} from 'date-fns';
import {startCase} from 'lodash-es';
import {GiveawayStatus} from '@prisma/client';
import {db} from 'shared/utils/db.server';

export const meta: MetaFunction = () => {
  return [
    {title: "Fanathem"},
  ];
};

export const loader = async () => {
  const giveaways = await db.giveaway.findMany({
    select: {
      id: true,
      imageUrl: true,
      title: true,
      endDate: true,
      categories: true,
    },
    where: {
      imageUrl: {
        not: null
      },
      details: {
        status: GiveawayStatus.ACTIVE,
      }
    },
    orderBy: {endDate: 'asc'},
    take: 3,
  });

  return json({
    promotions: giveaways.map(g => ({
      id: g.id,
      imageSrc: g.imageUrl as string,
      title: g.title,
      endingSoon: differenceInDays(g.endDate, new Date()) <= 3,
      categories: g.categories.map(startCase),
    }))
  })
}

export default function Index() {
  const {promotions} = useLoaderData<typeof loader>();

  return (
    <VStack gap={12}>
      <Hero/>
      <ActivePromotions promotions={promotions}/>
      <BuildingDreamsTogether/>
      <JoinVipFooter/>
    </VStack>
  );
}
